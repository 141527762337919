<template>
  <v-card flat>
    <v-form ref="sectorForm" lazy-validation>
      <v-text-field
        v-model="translations.name"
        :rules="validation.name"
        :label="$t('components.translations.sector.name')"
        required
      ></v-text-field>

      <v-textarea
        outlined
        name="input-7-4"
        v-model="translations.description"
        :label="$t('components.translations.sector.description')"
        :rules="validation.description"
        :counter="150"
      ></v-textarea>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: "SectorTranslation",
  props: {
    language: {
      type: String,
      default: null,
    },
    currentTranslations: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      validation: {
        name: [
          (v) => !!v || "Name is required",
        ],
        description: [
          (v) => !!v || "Description is required",
          (v) =>
            (v && v.length <= 150) ||
            "Description must be less than 150 characters",
        ],
      },

      translations: {
        name: "",
        description: "",
      },
    };
  },
  mounted() {
    this.setExistingTranslations();
  },
  watch: {
    //On user input emmit changes to parent element for axios call
    translations: {
      deep: true,
      handler: function () {
        let data = {
          language: this.language,
          data: this.translations,
        };

        this.$emit("translation-updated", data);
      },
    },
  },
  methods: {
    //In edit mode set the data for this language if exists
    setExistingTranslations() {
      this.currentTranslations.forEach((element) => {
        if (element.language == this.language) {
          this.translations.name = element.name
          this.translations.description = element.description
        }
        
      });
    },
  },
};
</script>